<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-office-building-plus
      </v-icon> Add Site
    </h2>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <v-card
        class="my-vcard-style"
      >
        <v-container fluid>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="siteName"
                  label="Site Name*"
                  class="purple-input"
                  hint="West Campus 1"
                  :rules="[(v) => !!v || 'This field is required']"
                  persistent-hint
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="locationTimeZone"
                  menu-props="auto"
                  :items="timeZonesByName"
                  label="Time Zone*"
                  prepend-icon="mdi-clipboard-text-clock-outline"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <address-form
                  ref="addressForm"
                  @addressEntered="assiginAddress($event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  id="map-button"
                  text
                  dark
                  large
                  class="mt-2"
                  color="#FF3700"
                  depressed
                  default
                  rounded
                  @click="openGoogleMaps()"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-google-maps
                  </v-icon>
                  Mark Site*
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="codn-div">
                      <p
                        v-bind="attrs"
                        v-on="on"
                      >
                        Latitude: <v-chip
                          v-if="!isLocationMarked"
                          color="#37474F"
                          outlined
                          class="codn-chip"
                        >
                          N/A
                        </v-chip>
                        <v-chip
                          v-else
                          color="#37474F"
                          outlined
                          class="codn-chip"
                        >
                          {{ markedLocationDetails.geometry.location.lat() }}
                        </v-chip>
                      </p>
                    </div>
                  </template>
                  <span>Coordinate values are based on the location you mark on the Google Maps and are highly crucial in monitoring user Check-In/Check-Out,<br> so always choose a location that is close/exact to your required site address.</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="codn-div">
                      <p
                        v-bind="attrs"
                        v-on="on"
                      >
                        Longitude: <v-chip
                          v-if="!isLocationMarked"
                          color="#37474F"
                          outlined
                          class="codn-chip"
                        >
                          N/A
                        </v-chip>
                        <v-chip
                          v-else
                          color="#37474F"
                          outlined
                          class="codn-chip"
                        >
                          {{ markedLocationDetails.geometry.location.lng() }}
                        </v-chip>
                      </p>
                    </div>
                  </template>
                  <span>Coordinate values are based on the location you mark on the Google Maps and are highly crucial in monitoring user Check-In/Check-Out,<br> so always choose a location that is close/exact to your required site address.</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <br>
        <p class="mandatory-text">
          *Indicates required field
        </p>
        <v-card-actions>
          <v-btn
            color="red darken-3"
            style="margin-left:10px"
            @click="cancelLocationForm()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="#FF3700"
            @click="addLocation()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
      <br>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import Constants from 'src/constants';
 import AddressForm from 'src/views/dashboard/component/AddressForm';

 export default {
    name: 'AddLocations',
    components: {
      'centre-spinner': spinner,
      'address-form': AddressForm,
    },
    data: () => ({
      loading: false,
      valid: false,
      siteName: '',
      locationTimeZone: '',
      address: {},
    }),
    computed: {
      savedLocationDetails () {
        return this.$store.getters['locations/getSavedLocationDetails'];
      },
      markedLocationDetails () {
        return this.$store.getters['locations/getMarkedLocationDetails'];
      },
      isLocationMarked () {
        if (Object.keys(this.markedLocationDetails).length > 0) {
          return true;
        }
        return false;
      },
      timeZones () {
        return this.$store.getters['userprofile/getTimeZones'];
      },
      timeZonesByName () {
        return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
      },
    },
    async mounted () {
      this.loading = true;
      await this.fillData();
      await this.getAllTimeZone();
      this.loading = false;
    },
    methods: {
      fillData () {
        if (Object.keys(this.savedLocationDetails).length === 0) {
          return;
        }
        this.siteName = this.savedLocationDetails.siteName;
        this.locationTimeZone = this.savedLocationDetails.locationTimeZone;
        this.$refs.addressForm.fillData(this.savedLocationDetails.address);
      },
      getAllTimeZone () {
        this.$store.dispatch('userprofile/fetchTimeZones');
      },
      openGoogleMaps () {
        this.loading = true;
        this.$refs.addressForm.getEnteredAdress();
        this.$store.dispatch('locations/saveLocationDetails', {
          siteName: this.siteName,
          locationTimeZone: this.locationTimeZone,
          address: this.address,
        }).then(() => {
          this.$router.push({ name: 'Google Maps' });
        });
      },
      cancelLocationForm () {
        this.$store.dispatch('locations/removeSavedLocationDetails');
        this.$store.dispatch('locations/removeMarkedLocationDetails');
        this.$router.push({ name: 'Locations' });
      },
      async addLocation () {
        this.$refs.addressForm.getEnteredAdress();
        if (this.$refs.form.validate() === false || Object.keys(this.address).length === 0 || Object.keys(this.markedLocationDetails).length === 0) {
         this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('locations/addLocation', {
         locationDetails: {
          name: this.siteName,
          timezone: this.locationTimeZone,
          address: this.address,
          latitude: this.markedLocationDetails.geometry.location.lat().toString(),
          longitude: this.markedLocationDetails.geometry.location.lng().toString(),
        },
        }).then(response => {
            this.$store.dispatch('alert/onAlert', {
            message: 'Location added successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
            this.$store.dispatch('locations/removeSavedLocationDetails');
            this.$store.dispatch('locations/removeMarkedLocationDetails');
            this.loading = false;
            this.address = {};
            this.$router.push({ name: 'Locations' });
          })
          .catch(errors => {
            this.loading = false;
          });
      },
      assiginAddress (address) {
        this.address = address;
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
#map-button {
  font-weight: bold;
}
.codn-div {
  margin-left: 25px;
  font-size: 16px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.codn-chip {
  margin-left: 10px;
}
.mandatory-text {
  margin-left: 20px;
}
</style>
